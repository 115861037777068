import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ClipboardCopy = makeShortcode("ClipboardCopy");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "test-data",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#test-data",
        "aria-label": "test data permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test data`}</h1>
    <h3 {...{
      "id": "patient--claimant-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patient--claimant-details",
        "aria-label": "patient  claimant details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patient / Claimant details`}</h3>
    <p>{`To get you going quickly, we’ve created the following test patients / claimants:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Email`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Last name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`First name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Date of birth`}</th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Medicare card`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Individual reference`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}>{`icare claim number`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "mailto:francis@medipass.io"
            }}>{`francis@medipass.io`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARTHUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FRANCIS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15/01/1987`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2951201842`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4000162`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "mailto:chantelle@medipass.io"
            }}>{`chantelle@medipass.io`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARTHUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHANTELLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15/01/1987`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2951201842`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "mailto:kym@medipass.io"
            }}>{`kym@medipass.io`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HEATH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KYM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22/02/1983`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2950975362`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "mailto:claudia@medipass.io"
            }}>{`claudia@medipass.io`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CONNOR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CLAUDIA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25/11/1998`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6950507482`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4001079`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Note that some stubs track claims against each patient and so duplicate claim attempts (same provider, same patient, same day, same item code) may be automatically declined by the system.
Note for Comcare claims, a specific combination of DOB and claim number is required. See "Comcare details" below.`}</p>
    <p>{`Note for Comcare claims, a specific combination of DOB and claim number is required. See "Comcare details" below.`}</p>
    <p>{`Test details for each funding type are as follows:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#payment-card-details"
        }}>{`Payment cards`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#medicare-details"
        }}>{`Medicare details`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#concession-details"
            }}>{`Concession details`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#dva-details"
            }}>{`DVA details`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hicaps-details"
        }}>{`HICAPS details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#comcare-details"
        }}>{`Comcare details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#icare-details"
        }}>{`icare details`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#wsv-details"
        }}>{`WSV deatils`}</a></li>
    </ul>
    <h3 {...{
      "id": "payment-card-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#payment-card-details",
        "aria-label": "payment card details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Payment card details`}</h3>
    <p>{`Use a valid test card number:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Test cards`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Card number`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`MasterCard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5555555555554444`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visa`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4111111111111111`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visa`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4242424242424242`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Payment card can have any valid expiration, some have set responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Expiry date`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Simulated response`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`05 / 23`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`APPROVED`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`08 / 28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`TIMED_OUT`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`04 / 27`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`EXPIRED_CARD`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Note: At present, the Medipass STG environement is connected live to payment card schemes test networks. Payment schemes do not always honour simulated timeout/expired responses.`}</li>
    </ul>
    <p>{`Payment card CSC/CVV can be one of:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`CSC/CVV`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Simulated response`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`100`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MATCH (APPROVED)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`200`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NO MATCH (DECLINED)`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "apple-pay-and-google-pay",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#apple-pay-and-google-pay",
        "aria-label": "apple pay and google pay permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Apple Pay and Google Pay`}</h4>
    <p>{`Note that these payment options require a special setup for testing and can be problematic.`}</p>
    <p>{`Generally, Google Pay can only be tested in production. Contact the Medipass support team at `}<a parentName="p" {...{
        "href": "mailto:hello@medipass.com.au"
      }}>{`hello@medipass.com.au`}</a>{` for other testing options.`}</p>
    <p>{`For Apple Pay, follow the `}<strong parentName="p">{`Create a Sandbox Tester Account and Test Card Number`}</strong>{` instructions as posted by Apple: `}<a parentName="p" {...{
        "href": "https://developer.apple.com/apple-pay/sandbox-testing/"
      }}>{`https://developer.apple.com/apple-pay/sandbox-testing/`}</a></p>
    <h3 {...{
      "id": "medicare-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#medicare-details",
        "aria-label": "medicare details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare details`}</h3>
    <p>{`Valid test details include:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Medicare card`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Individual reference`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6950507392`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HENDRIX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FELICA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14/04/1975`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2950975362`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HEATH`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KYM`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22/02/1983`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6950507482`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CONNOR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CLAUDIA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25/11/1998`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3950387652`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`BAXTER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CORAZON`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28/12/1983`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2951201842`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARTHUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MADELAINE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15/01/1987`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2951201842`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARTHUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`CHANTELLE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02/01/1986`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2951201842`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARTHUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FRANCIS`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13/02/2012`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2951201842`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ARTHUR`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DIMITY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10/02/2011`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Alternative / exception test details include:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Medicare card`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Individual reference`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Enrolment type`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2297460335`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Devo`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Onlyname`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01/01/1980`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Only Name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2298039873`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jones`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Marrianna-Louise`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`19/05/1967`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Long First Name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2298325523`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Thompson-Blackwood`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Annette`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15/08/2009`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Long Name`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2297582343`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Xpatriot`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`English`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15/09/1975`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RHCA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2297402204`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Foreigner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`French`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`16/05/1980`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Expired RHCA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5500393923`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Boyes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`23/05/1980`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Lost card`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2950862041`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jones`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Happy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`04/05/1964`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Transferred to different card, Old card quoted.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2296510126`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jones`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sad`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15/09/1964`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Deceased (20112008)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2398125261`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Doe`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`John`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13/09/1979`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unknown on Medicare Australia systems`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2298264833`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Watts`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gregory`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12/05/1970`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Old Issue Number`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2295919745`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Davis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Eva`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`02/05/1979`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Safety Net Patient - concession entitled`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "concession-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#concession-details",
        "aria-label": "concession details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Concession details:`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Medicare Card`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Individual reference`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Entitlement Id`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Enrolment Type`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2293653284`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Leonard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bobby`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10/02/1958`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100015482A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Concession Entitled`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "medicare-bulk-bill-and-dva-simulated-responses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#medicare-bulk-bill-and-dva-simulated-responses",
        "aria-label": "medicare bulk bill and dva simulated responses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare Bulk Bill and DVA simulated responses`}</h4>
    <p>{`Medicare Bulk Bill and DVA submissions are usually processed overnight. To quickly simulate responses (and test configured webhooks), transactions can be stubbed. Stubs can be applied at multiple levels:`}</p>
    <ul>
      <li parentName="ul">{`Submission`}</li>
      <li parentName="ul">{`Processing`}</li>
      <li parentName="ul">{`Processing claim item`}</li>
      <li parentName="ul">{`Payment`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Stub text`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Level`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Expected response`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Notes`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_OK`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submissions (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submission succeeds`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_DOWN`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submissions (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submission fails with 3004 (medicare down)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim will be successful after the next batch run. Approx every 2 hour`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_FAIL`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submissions (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submission returns a fail 3999`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim will not be resubmitted`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_FAIL_XXXX`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submissions (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Submission returns a fail XXXX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Replace `}<em parentName="td">{`XXXX`}</em></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_PROCESSING_ITEM_FAIL`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Processing claim item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Processing report returns with Explaination code 160`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_PROCESSING_ITEM_FAIL_XXX`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Processing claim item`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Processing report returns with Explaination code XXX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Links to return codes: `}<a parentName="td" {...{
              "href": "https://www.servicesaustralia.gov.au/organisations/health-professionals/topics/medicare-digital-claiming-return-codes/33171#medicarereasoncodes"
            }}>{`Medicare`}</a>{`, `}<a parentName="td" {...{
              "href": "https://www.servicesaustralia.gov.au/organisations/health-professionals/topics/dva-reason-codes/31966"
            }}>{`DVA`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_PAYMENT_FAIL`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payment report returns with fail 8002`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`STUB_PAYMENT_FAIL_XXXX`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Payment report returns with fail XXXX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <p>{`Stub text(s) are applied on claim item's `}<em parentName="p">{`Service Text`}</em>{` field and should be set on the first claim item of the claim, with the exception of STUB_PROCESSING_ITEM_FAIL, which can differ for each claim item.
Stub outcome can also be applied on different levels ie. Submission, Processing claim item, Payment. To initiate a stub, Submission level is `}<strong parentName="p">{`mandatory`}</strong>{`.`}</p>
    <p>{`Examples:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`STUB_OK;STUB_PROCESSING_ITEM_FAIL_123`}</strong>{`: Submission submits successfully, but processing report returns an explaination code of 123 and results in no benefits paid for the item.`}</li>
      <li parentName="ul"><strong parentName="li">{`STUB_DOWN;STUB_PAYMENT_FAIL_1234`}</strong>{`: Submission failed because medicare was down. After a couple of hours, re-submission succeeds with a successful approval of claim. Payment fails with an error code of 1234`}</li>
    </ul>
    <h4 {...{
      "id": "dva-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#dva-details",
        "aria-label": "dva details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DVA details`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Veteran File No`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`First name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QX901543`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`FIONA`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ANNE-MARIE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10/04/1954`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QX901544`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GRAHEME`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`DIMITRY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`27/05/1961`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QX901545`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JACKI`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GERRY`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13/07/1969`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`QX901546`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`KEVIN`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JADE`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28/09/1956`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "provider-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#provider-details",
        "aria-label": "provider details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Provider details`}</h4>
    <p>{`To get going quickly, we’ve created test business, practice and providers. Each test business has:`}</p>
    <ul>
      <li parentName="ul">{`two practice locations`}</li>
      <li parentName="ul">{`three providers: one allied healthcare professional, one GP and one Medical Specialist`}</li>
      <li parentName="ul">{`Providers enabled for claims across all HICAPS funds, all Medicare functions, all CTP / icare functions`}</li>
      <li parentName="ul">{`A merchant account enabled for all card payments`}</li>
    </ul>
    <p>{`Contact the Medipass support team at `}<a parentName="p" {...{
        "href": "mailto:hello@medipass.com.au"
      }}>{`hello@medipass.com.au`}</a>{` for access credentials and API keys.`}</p>
    <p>{`Alternatively, you can use the details listed below:`}</p>
    <h5 {...{
      "id": "servicing-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#servicing-providers",
        "aria-label": "servicing providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Servicing providers`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider number`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Last name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`First name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Enabled funders`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Physiotherapist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2429581T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Han`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Clifton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HICAPS, Medicare, DVA, icare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`General Practitioner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2409661B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Miranda`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Raphael`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HICAPS, Medicare, DVA, icare`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medical Specialist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2429591L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browning`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Charlene`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`HICAPS, Medicare, DVA, icare`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "payee-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#payee-providers",
        "aria-label": "payee providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Payee providers`}</h5>
    <p>{`(Medicare only)
Any valid servicing provider can be used as payee provider.`}</p>
    <h5 {...{
      "id": "referee-providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#referee-providers",
        "aria-label": "referee providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Referee providers`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider number`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Provider name`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`General Practitioner`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2054781W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dr Brenda Reed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Medical Specialist`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2121331W`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dr Vivian Mortier`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "hicaps-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hicaps-details",
        "aria-label": "hicaps details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HICAPS details`}</h3>
    <p>{`For patients/members:`}</p>
    <ul>
      <li parentName="ul">{`Select any supported health fund`}</li>
      <li parentName="ul">{`You can input any data for a health fund card as long as it passes the input validation:`}
        <ul parentName="li">
          <li parentName="ul">{`Any membership number, some may require an alpha character where noted`}</li>
          <li parentName="ul">{`Any card issue number where prompted - start with ‘01’, else if card issue date enter any previous date`}</li>
          <li parentName="ul">{`A unique patient identifier - start with ‘01’`}</li>
        </ul>
      </li>
    </ul>
    <h5 {...{
      "id": "simulated-hicaps-claim-responses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#simulated-hicaps-claim-responses",
        "aria-label": "simulated hicaps claim responses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`simulated HICAPS claim responses`}</h5>
    <p>{`The health fund claiming engine is stubbed in non-production environments. By default, claims are successful with each item receiving a 60% benefit (rounded to the nearest dollar less than the benefit). You can use other dollar amounts to simulate different scenarios:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Claim total amount`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Simulated response`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$12.XX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entire claim is rejected with response code of XX.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$11.50`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Internal server error.`}</td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Claim item amount`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Simulated response`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$13.XX`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item is rejected with response code of XX.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$21.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item is no gap i.e. full benefit.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$22.00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Claim item is all gap i.e. no benefit.`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "payment-and-health-fund-physical-cards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#payment-and-health-fund-physical-cards",
        "aria-label": "payment and health fund physical cards permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Payment and health fund physical cards`}</h5>
    <p>{`Test payment and health fund cards can be arranged for testing. Contact the Medipass support team at `}<a parentName="p" {...{
        "href": "mailto:hello@medipass.com.au"
      }}>{`hello@medipass.com.au`}</a>{` to arrange test cards.`}</p>
    <h3 {...{
      "id": "comcare-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#comcare-details",
        "aria-label": "comcare details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare details`}</h3>
    <p>{`Claims submitted to Comcare are validated by claim number and DOB. Of note, the format of the claim number must include a forward slash followed by two digits. For testing purposes, any patient name can be used with the following claim numbers and DOB:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1048428/2 `}<ClipboardCopy text="1048428/2" mdxType="ClipboardCopy" /></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1963-29-04`}</td>
        </tr>
      </tbody>
    </table>
    <blockquote>
      <p parentName="blockquote">{`Note:If the above details do not work please contact our integration team `}<a parentName="p" {...{
          "href": "mailto:partnerships@medipass.io"
        }}>{`partnerships@medipass.io`}</a>{`.`}</p>
    </blockquote>
    <h5 {...{
      "id": "stubbing",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#stubbing",
        "aria-label": "stubbing permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stubbing`}</h5>
    <p>{`For Comcare injured worker insurance claims, any patient name can be used with the following claim number`}</p>
    <p>{`To receive a simulated response :`}</p>
    <ul>
      <li parentName="ul">{`7777/77 `}<ClipboardCopy text="7777/77" mdxType="ClipboardCopy" /></li>
    </ul>
    <p>{`To receive a simulated rejection:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim total amount`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Simulated response`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$0.11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entire claim is rejected`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "icare-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icare-details",
        "aria-label": "icare details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`icare details`}</h3>
    <p>{`For workers insurance claims, any patient name can be used with the following claim numbers:`}</p>
    <p>{`To receive a simulated response`}</p>
    <ul>
      <li parentName="ul">{`7777777`}</li>
    </ul>
    <p>{`To submit to icare's testing environment claiming engine`}</p>
    <ul>
      <li parentName="ul">{`4000162`}</li>
      <li parentName="ul">{`4001079`}</li>
    </ul>
    <h5 {...{
      "id": "simulated-icare-claim-responses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#simulated-icare-claim-responses",
        "aria-label": "simulated icare claim responses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`simulated icare claim responses`}</h5>
    <p>{`The icare test claiming engine will not generate a response unless manually triggered by icare staff. Please contact us if this is required. It is preferred where possible to test using simulated responses. Only specified claim numbers above will receive a simulated response. By default, all claims from these numbers will receive an approved response from the stub. To simulate other responses, see the following table:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Claim total amount`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Simulated response`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$21.36`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entire claim is rejected with a randomised rejection reason and rejection details`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`$31.22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Entire claim is under-review by icare`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "wsv-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#wsv-details",
        "aria-label": "wsv details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WSV details`}</h3>
    <p>{`The WSV test claiming engine will not generate a response unless manually triggered by WSV staff. To test  cases where invoices go “Under Review” Please contact us . It is preferred where possible to test using simulated responses. Only the specified claim numbers below will receive a simulated response. `}</p>
    <h4 {...{
      "id": "providers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#providers",
        "aria-label": "providers permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Providers:`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Provider Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Provider Number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Frist name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`ABN`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Physio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2382464A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`RT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PHYSIO CHIRO SERVICE PROVIDER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`59 336 445 411`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Physio`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0710446T`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MT`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ABLITT PHYSIO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 144 889 636`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0111827Y`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`John`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GENERAL PRACTIONER GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`99 128 211 843`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0111829X`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`John`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`GENERAL PRACTIONER GP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`99 128 211 843`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PH0060`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`J`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PHARMACY SERVICE PROVIDER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`78 414 009 530`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pharmacy`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PH0038`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PHARMACY SERVICE PROVIDER`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43 083 841 007`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "claims",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#claims",
        "aria-label": "claims permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Claims:`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim Type`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Claim Number`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Frist name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Last name`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of birth`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Date of Injury`}</strong></th>
          <th parentName="tr" {...{
            "align": null
          }}><strong parentName="th">{`Comments`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Standard`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000492`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Standardclaim`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/07/1980`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30/05/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can invoice for all types (GP, Physio, Pharmacy) Updated to reject depression, anxiety and headache migraine pharmacy categories`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Catastrophic`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000502`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catastropic`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/06/1990`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/03/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can invoice for all types (GP, Physio, Pharmacy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Provision Payments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000515`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JPPClaimohn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/08/1989`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30/03/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Can invoice for all types (GP, Physio, Pharmacy)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`EMPLOYER Liability NOT ACCEPTED`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000528`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`MANDLNO`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/01/1995`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1/02/2021`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Invoice will fail`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Self Insured`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PP`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jensen`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Gibb`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13/09/1949`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Exclusion period`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12210000272`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exclusion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Medipass`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13/06/1983`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`01/01/2020`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Exclusion - services not valid within the below dates. If you bill either side of these dates then invoice will be approved/Pending Doctor between 01/10/21 - 5/11/21 Physio between 01/10/21 -05/11/2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gap in Treatment`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1091024377`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Anthony`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Blazer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10/06/1958`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18/12/1991`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No claim for more than 3 years; this should go to WSV for review.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      